<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <search :search-data="searchData" />
        </b-col>
        <b-col cols="auto">
          <b-button
            variant="primary"
            :to="$route.path + '/customer-search'"
          >
            <FeatherIcon icon="PlusIcon" /> Oluştur
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <offer-list
        :data-list="dataList"
        :fields="fields"
      />
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BPagination, BButton, BCardFooter,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Search from '@/views/BatteryOffers/elements/Search.vue'
import OfferList from '@/views/BatteryOffers/Index/OfferList.vue'

const tableName = 'com_battery_offer'
export default {
  name: 'Index',
  components: {
    BRow,
    BCol,
    BCard,
    BPagination,
    BButton,
    BCardFooter,
    OfferList,
    Search,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: 'title',
          label: 'Teklif',
        },
        {
          key: 'customer',
          label: 'Müşteri',
        },
        {
          key: 'username',
          label: 'Danışman',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'text-right',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          'com_customer.name AS customer',
          'com_battery_offer.title AS title',
          'com_battery_offer.odate AS odate',
          'com_battery_offer.onumber AS onumber',
          'com_battery_offer_status.title AS offers_status',
          'com_cars.license_plate AS license_plate',
          'com_user.name AS username',
        ],
        order_by: ['com_battery_offer.id', 'DESC'],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['batteryOffers/getOffers']
    },
    dataCounts() {
      return this.$store.getters['batteryOffers/getOffersCount']
    },
    saveData() {
      return this.$store.getters['batteryOffers/getOfferSaveStatus']
    },
    search() {
      return this.$store.getters['batteryOffers/search']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 10
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    searchData() {
      if (this.search.keyword) {
        this.dataQuery.or_like = {
          'customer.company': this.search.keyword,
          'customer.phone': this.search.keyword,
        }
      }
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('batteryOffers/offersList', this.dataQuery)
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
